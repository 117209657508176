@import '../../styles/variables';
.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  .icon {
    width: 7.5rem;
    height: 7.5rem;
    margin-top: 6.4rem;
  }
  .submittedSuccessfully {
    font-size: 2rem;
    line-height: 2rem;
    font-family: BoschSans-Black, serif;
    color: $black;
    margin-top: 2.5rem;
  }
  .thankYou {
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: BoschSans-Regular, serif;
    color: $black;
    margin-top: 2rem;
    width: 34.5rem;
    text-align: center;
  }
  .youEarnedPoints1 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: BoschSans-Regular, serif;
    color: $black;
    .youEarnedPoints2 {
      font-family: BoschSans-Black, serif;
    }
  }
}
