$very-light-blue: #e8f1ff;
$dark-blue: #005691;
$light-blue: #007bc0;
$warm-gray: #929292;
$pale-gray: #eff1f2;
$black: #000000;
$red: #e20015;
$gray: #595e62;
$white: #ffffff;
$marineBlue: #003d6a;
$deepSeaBlue: #00558d;
$boschRed: #ea0016;
$boschBlue60: #0096e8;
$boschBluePro: #003E64;
$boschBlueLink: #7EBDFF;
