@import '../styles/variables';

.SurveyComponentContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .title {
    width: 34.5rem;
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: $black;
    font-family: 'BoschSans-Medium', serif;
    margin-top: 2.85rem;
    text-align: left;
    .require {
      color: $red;
    }
  }
  .single {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .option {
      background-color: $pale-gray;
      width: 33.6rem;
      min-height: 4.8rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .description {
        color: $gray;
        font-size: 1.3rem;
        font-family: 'BoschSans-Regular', serif;
        margin-left: 2rem;
        padding: 1rem 0;
      }
      .selectedDescription {
        color: $light-blue;
      }
      .number {
        --icon-size: 1.8rem;
        margin-right: 2.6rem;
      }
    }
    .selectedOption {
      background-color: $very-light-blue;
    }
  }
  .multi {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .option {
      background-color: $pale-gray;
      width: 33.6rem;
      min-height: 4.8rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .description {
        color: $gray;
        font-size: 1.3rem;
        font-family: 'BoschSans-Regular', serif;
        margin-left: 2rem;
        padding: 1rem 0;
      }
      .selectedDescription {
        color: $light-blue;
      }
      .number {
        --icon-size: 1.8rem;
        margin-right: 2.6rem;
      }
    }
    .selectedOption {
      background-color: $very-light-blue;
    }
  }
  .rating {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .score {
      --star-size: 3.2rem;
      width: 27.6rem !important;
      justify-content: space-between !important;
    }
  }
  .completion {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .textDiv {
      width: 34rem;
      background-color: $pale-gray;
      .text {
        margin: auto;
        width: 33rem;
      }
    }
  }
  .footer {
    width: 33.6rem;
    //height: 4.8rem;
    //position: fixed;
    margin: 4.5rem 0;
    display: flex;
    justify-content: space-between;
    .footerFirst {
      background-color: $dark-blue;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 4.8rem;
      font-family: 'Bosch-Icon', serif;
      color: $white;
      font-size: 2rem;
    }
    .footerMiddle {
      background-color: $dark-blue;
      width: 14.6rem;
      height: 100%;
      text-align: center;
      line-height: 4.8rem;
      font-family: 'Bosch-Icon', serif;
      color: $white;
      font-size: 2rem;
    }
    .footerLast {
      display: flex;
      flex-direction: column;
      .submitTips {
        text-align: center;
        font-family: 'BoschSans-Regular', serif;
        font-size: 1.2rem;
        width: 32rem;
        color: $warm-gray;
        margin-bottom: 2.5rem;
      }
    }
  }
}
