@import '../../styles/variables';

.welcomeRoot {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  .boschLogo {
    //position: absolute;

    width: 14rem;
    height: 5rem;
    align-self: flex-start;
  }
  .welcomeContainer {
    background: repeating-linear-gradient(to top, $deepSeaBlue, $marineBlue);
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .banner {
    height: 0.6rem;
    min-height: 0.6rem;
    width: 100%;
  }
  .appIcon {
    width: 8.5rem;
    height: 8.5rem;
    min-height: 8.5rem;
    margin-top: 2.4rem;
  }
  .span_bl {
    color: $white;
    font-size: 2.4rem;
    font-family: 'BoschSans-Black', serif;
    line-height: 2.6rem;
    text-align: center;
  }
  .bottomContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 2.8rem - 2.8rem);
    margin-bottom: 0;
    margin-top: auto;
    padding-bottom: 2.8rem;
    .joinBtn {
      background-color: $boschRed;
      height: 4.8rem;
      margin-top: 0;
      color: $white;
      text-align: center;
      line-height: 4.8rem;
      width: 100%;
      font-family: 'BoschSans-Black', serif;
      font-size: 1.6rem;
    }
    .divider {
      margin-top: 2rem;
      width: 100%;
      height: 0.1rem;
      background-color: $white;
      opacity: 50%;
    }
    .subscribeTips {
      margin-top: 2rem;
      width: 100%;
      color: $white;
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.2rem;
    }
    .subscribeBtn {
      height: 4.8rem;
      margin-top: 1.5rem;
      color: $white;
      text-align: center;
      line-height: 4.8rem;
      width: 100%;
      font-family: 'BoschSans-Black', serif;
      font-size: 1.6rem;
      border-style: solid;
      border-color: $white;
      border-width: 0.1rem;
    }
    .allRightInfo {
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.1rem;
      text-align: center;
      color: $white;
      opacity: 50%;
      margin-top: 6rem;
    }
  }
  .referralBottomContainer {
    .icon {
      font-family: 'Bosch-Icon', serif;
      font-size: 20px;
      width: 20px;
      height: 20px;
      color: $white;
      text-align: center;
      background-color: $boschBlue60;
      border-radius: 10px;
      margin-right: 0.5rem;
    }
    .captchaImg {
      background-color: $light-blue;
      height: 4.4rem;
      color: $white;
      text-align: center;
      line-height: 4.4rem;
      width: 22rem;
      font-family: 'BoschSans-Medium', serif;
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .inviteMessage {
      font-family: 'BoschSans-Medium', serif;
      font-size: 1.3rem;
      color: $white;
      text-align: left;
      line-height: 20px;
    }
    .inviteMessageHighlight {
      font-family: 'BoschSans-Black', serif;
    }
    .sellerInviteHighlight {
      font-family: 'BoschSans-Black', serif;
      color: $boschBlue60;
      background-color: white;
      border-radius: 2rem;
      padding: 2px 1rem 2px 1rem;
      display: inline-flex;
      line-height: 15px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }
    .joinBtn {
      margin-top: 1rem;
    }
    .baseInput {
      background-color: $white;
      height: 4.4rem;
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.4rem;
      margin-top: 1rem;
    }
    .captchaInput {
      background-color: $white;
      height: 4.4rem;
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.4rem;
      margin-top: 1rem;
    }
    .funcsImage {
      width: 14px;
      height: 14px;
    }
    .iconContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0.6rem;
    }
    .firstIconContent {
      margin-top: 1rem;
    }
    .iconText {
      font-size: 1.3rem;
      text-align: left;
      color: $white;
      font-family: 'BoschSans-Regular', serif;
      align-self: end;
      margin-left: 0.8rem;
    }
    .funcsTitle {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 0;
    }
    .funcsContent {
      width: 100%;
      margin-top: 1rem;
    }
    .adm-input-element {
      margin-left: 1rem;
    }
    .verifyBtn {
      background-color: $light-blue;
      height: 4.4rem;
      color: $white;
      text-align: center;
      line-height: 4.4rem;
      width: 10rem;
      font-family: 'BoschSans-Medium', serif;
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .countryCode {
      height: 4.4rem;
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.4rem;
      position: absolute;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
    .phoneContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      .baseInput {
        padding-left: 3.4rem;
      }
    }
    .tcPpText {
      font-family: 'BoschSans-Regular', serif;
      font-size: 1.1rem;
      line-height: 1.6rem;
      color: $white;
      align-self: flex-start;
      margin-top: 1.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }
    .tcPpHighlight {
      color: $boschBlue60;
      text-decoration: underline;
    }
    .adm-checkbox-content {
      font-size: 1.1rem;
    }
    .adm-checkbox-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
    .adm-input-element {
      font-size: 1.4rem;
    }
    .verifyDisabled {
      background-color: $warm-gray;
    }
    .countDownSeconds {
      color: $white;
      font-size: 2rem;
      font-family: 'BoschSans-Medium', serif;
    }
  }
}

.ClauseRoot {
  width: 100%;
  height: 100%;
}

.failedMaskClass .adm-toast-main {
  background-color: $white !important;
  color: $boschRed !important;
  font-family: 'BoschSans-Regular', serif !important;
  max-width: 80% !important;
  padding: 1.6rem 1.2rem !important;
  .adm-toast-icon {
    color: $boschRed;
  }
}
.successMaskName {
  .adm-toast-main {
    background-color: $white !important;
    color: green !important;
    font-family: 'BoschSans-Regular', serif !important;
    max-width: 80% !important;
    padding: 1.6rem 1.2rem !important;
    .adm-toast-icon {
      color: green;
    }
  }
}

.thanksMask {
  display: flex;
  justify-content: center;
  align-items: center;

  .adm-mask-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thanksContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 90%;
    min-height: 50%;
  }
  .fillInfo {
    width: 6rem;
    height: 8rem;
    margin-top: 2rem;
  }
  .thanksMessage {
    font-family: 'BoschSans-Bold', serif;
    font-size: 1.5rem;
    color: black;
    max-width: 80%;
    margin-top: 2rem;
  }
  .installMessage {
    font-family: 'BoschSans-Medium', serif;
    font-size: 1.2rem;
    color: gray;
    max-width: 90%;
    margin-top: 2rem;
  }
  .installBtn {
    background-color: #005691;
    height: 4.8rem;
    margin-top: 2rem;
    color: $white;
    text-align: center;
    line-height: 4.8rem;
    width: 90%;
    font-family: 'BoschSans-Medium', serif;
    font-size: 1.6rem;
    border-radius: 5px;
  }
  .laterBtn {
    background-color: white;
    height: 4.8rem;
    margin-top: 2rem;
    color: #007bc0;
    text-align: center;
    line-height: 4.8rem;
    width: 90%;
    font-family: 'BoschSans-Medium', serif;
    font-size: 1.6rem;
    border-width: 1px;
    border-style: solid;
    border-color: #005691;
    border-radius: 5px;
  }
}
