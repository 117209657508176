@import '../../styles/variables';

.surveyRoot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  .line1 {
    margin-top: 3.2rem;
    font-family: 'BoschSans-Bold', serif;
    line-height: 2.3rem;
    font-size: 1.8rem;
    width: 34.5rem;
  }
  .span {
    width: 33rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $black;
    font-family: 'BoschSans-Regular', serif;
    margin-top: 2.3rem;
    word-wrap: break-word;
  }
  .span_bl {
    font-family: 'BoschSans-Bold', serif;
  }
  .processBar {
    width: 33rem;
    margin-top: 2.77rem;
    --track-width: 0.6rem;
  }
  .step {
    color: $warm-gray;
    align-self: flex-start;
    margin-left: 2.25rem;
    font-size: 1.1rem;
    font-family: BoschSans-Regular, serif;
    margin-top: 0.6rem;
    margin-bottom: 2.3rem;
  }
  .divider {
    width: 100%;
    height: 1rem;
    background-color: $pale-gray;
  }
  .surveyContent {
    width: 100%;
    flex: 1 !important;
  }
}
