@import '../../styles/variables';

.wechatRoot {
  background-color: $marineBlue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .banner {
    width: 100%;
    height: 1rem;
  }
  .logo {
    width: 14rem;
  }
  .mainContent {
    width: 95%;
    flex: 1;
    background-color: $white;
    margin-bottom: 4rem;
    align-self: center;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .infoIcon {
      font-family: 'iconfont', serif;
      font-size: 4rem;
      color: $dark-blue;
      margin-top: 2rem;
    }
    .notice {
      font-family: BoschSans-Regular, serif;
      font-size: 2.5rem;
      color: $dark-blue;
      margin-top: 1rem;
    }
    .noticeSon1 {
      font-family: BoschSans-Regular, serif;
      font-size: 1.35rem;
      margin-top: 1rem;
      width: 80%;
      text-align: center;
    }
    .noticeSon2 {
      font-family: BoschSans-Regular, serif;
      font-size: 1.35rem;
      margin-top: 1rem;
      width: 90%;
      text-align: center;
    }
    .MiniProgramCode {
      margin-top: 2rem;
      width: 18rem;
      height: 18rem;
    }
    .nextButtonDiv {
      margin: auto auto;
      width: 18rem;
      .nextButton {
        --border-color: $dark-blue;
        --border-radius: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}
