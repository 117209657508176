@import 'styles/globelStyles';

@font-face {
  font-family: 'BoschSans-Black';
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/BoschSans-Black.woff2) format('woff2'),
    url(./assets/fonts/BoschSans-Black.woff) format('woff');
}

@font-face {
  font-family: 'BoschSans-Light';
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/BoschSans-Light.woff2) format('woff2'),
    url(./assets/fonts/BoschSans-Light.woff) format('woff');
}

@font-face {
  font-family: 'BoschSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/BoschSans-Regular.woff2) format('woff2'),
    url(./assets/fonts/BoschSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'BoschSans-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/BoschSans-Medium.woff2) format('woff2'),
    url(./assets/fonts/BoschSans-Medium.woff) format('woff');
}

@font-face {
  font-family: 'BoschSans-Bold';
  font-style: normal;
  font-weight: bold;
  src: url(./assets/fonts/BoschSans-Bold.woff2) format('woff2'),
    url(./assets/fonts/BoschSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Bosch-Icon';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url(./assets/fonts/Bosch-Icon-2-5/fonts-web/Bosch-Icon.woff2)
      format('woff2'),
    url(./assets/fonts/Bosch-Icon-2-5/fonts-web/Bosch-Icon.woff) format('woff');
}

@font-face {
  font-family: 'iconfont';
  src: url(./assets/fonts/iconfont.eot); /* IE9*/
  src: local('?'),
    url(./assets/fonts/iconfont.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(./assets/fonts/iconfont.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
html {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
