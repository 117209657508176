@import '../../styles/variables';
.container {
  $fontSize: 1rem;
  $center: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $boschBluePro;
  .content {
    color: $white;
    width: 90%;
    margin: 40px auto 20px auto;
    display: flex;
    flex-direction: column;
    h3 {
      font-size: $fontSize + 2rem;
      text-align: $center;
      padding: 0;
      margin: 0;
    }
    span {
      display: inline-block;
      font-size: $fontSize + 0.4rem;
      margin-top: 20px;
      line-height: 20px;
    }
    p {
      display: block;
      font-size: $fontSize + 0.4rem;
      margin-top: 20px;
      line-height: 20px;
      a {
        display: inline-block;
        margin: 0 5px;
        color: $white;
        font-weight: 400;
      }
      .link-website {
        text-decoration: underline;
        font-weight: 800;
      }
    }
  }
  .button-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    margin: 0 auto;
    $linkHeight: 48px;
    .warp {
      width: 90%;
      margin: 0 auto;
    }
    a {
      display: block;
      width: 100%;
      height: $linkHeight;
      line-height: $linkHeight;
      text-align: $center;
      align-items: $center;
      text-decoration: none;
      font-size: $fontSize + 0.6rem;
      color: $boschBlueLink;
    }
    .pro_website {
      color: $boschBluePro;
      background-color: $white;
    }
  }
}
.header-colorline {
  flex: none;
  background-image: url('../../assets/images/colored-line.svg'),
      linear-gradient(
      to right,
      #942331,
      #931813 4%,
      #b12638 8%,
      #af1917 12%,
      #b11917 13%,
      #cb1517 15%,
      #d51317 17%,
      #c41b2e 18%,
      #982e69 21%,
      #88357f 21%,
      #853580 24%,
      #6e368c 26%,
      #3a4190 29%,
      #14387f 32%,
      #312a6f 42%,
      #283587 49%,
      #1d61a1 56%,
      #347eac 59%,
      #50a2bb 63%,
      #5bb0c0 66%,
      #55aebf 67%,
      #46a7bc 69%,
      #2b9db7 71%,
      #078eaf 74%,
      #008bae 74%,
      #009972 83%,
      #00a24c 89%,
      #00937d
  );
  background-size: 100% 100%;
  width: 100%;
  height: 6px;
  z-index: 1;
}
.header-container {
  height: auto;
  background-color: $white;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-height: 375px;
  background-image: url('../../assets/images/imageplaceholder.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.loadingcanvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $boschBluePro;
  text-align: center;
  color: $white;
  align-items: center;
  font-size: large;
  display: flex;
  justify-content: center;
}