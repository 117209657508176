@import '../../styles/variables';

.visitorRoot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  .commonSwipePage {
    height: 100%;
    //background-color: #007bc0;
  }
  .swipeBg {
    height: 100%;
    width: 100%;
  }
  .boschLogo {
    position: absolute;
    left: 0;
    top: 0;
    width: 14rem;
    height: 5rem;
  }
  .firstSwipePage {
  }

  .span {
    color: $white;
    font-family: 'BoschSans-Regular', serif;
  }
  .span_bl {
    color: $white;
    font-size: 3.2rem;
    font-family: 'BoschSans-Black', serif;
    line-height: 4rem;
    letter-spacing: normal;
    font-weight: bold;
  }
  .contentContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .firstContainer {
    bottom: 12.2rem;
    left: 3rem;
  }
  .secondContainer {
    bottom: 10.7rem;
    left: 2.5rem;
    right: 2.5rem;
    .span_bl {
      margin-left: 0.5rem;
    }
    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top: 2rem;
    }
    .iconContent {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 9.8rem;
      height: 10.4rem;
      border-style: solid;
      border-radius: 6px;
      border-width: 1px;
      border-color: $white;
      margin-right: 1.5rem;
    }
    .icon {
      font-family: 'Bosch-Icon', serif;
      font-size: 4.8rem;
      color: $white;
      text-align: center;
    }
    .iconText {
      font-size: 1.1rem;
      text-align: center;
      margin-top: 0.7rem;
      color: $white;
      font-family: 'BoschSans-Bold', serif;
    }
  }
  .thirdContainer {
    bottom: 15rem;
    left: 3rem;
  }
  .fourthContainer {
    bottom: 16rem;
    left: 3.8rem;
    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 1.7rem;
    }
    .iconContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0.6rem;
    }
    .iconRight {
      width: 3.1rem;
      height: 3.1rem;
    }
    .smallIcon {
      font-family: 'Bosch-Icon', serif;
      font-size: 2rem;
      color: $white;
      text-align: center;
      width: 2.3rem;
      height: 2.3rem;
    }
    .iconText {
      font-size: 1.8rem;
      text-align: left;
      margin-left: 0.2rem;
      color: $white;
      font-family: 'BoschSans-Bold', serif;
      align-self: end;
    }
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .indicatorItem {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-top: 8px;
  }
  .indicatorContainer {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
  .itemUnselected {
    background-color: $white;
  }
  .itemSelected {
    background-color: $light-blue;
  }
  .nextButton {
    left: 3.8rem;
    right: 3.8rem;
    height: 5rem;
    position: absolute;
    bottom: 10rem;
    font-family: 'BoschSans-Black', serif;
    font-size: 1.6rem;
    color: $white;
    background-color: $boschBlue60;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 10rem;
  }
}
