@import './variables';

.button-primary {
  background-color: $dark-blue;
  width: 33.6rem;
  height: 4.8rem;
  text-align: center;
  line-height: 4.8rem;
  font-family: 'BoschSans-Black', serif;
  color: $white;
  font-size: 2rem;
}
:root:root {
  --adm-color-primary: rgb(0, 123, 192);
}

.adm-checkbox .adm-checkbox-icon {
  border-radius: 0 !important;
}
.adm-rate score {
}
