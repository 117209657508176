@import '../../styles/variables';
.distribute {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background-color: $marineBlue;
  background-image: linear-gradient(to right, $marineBlue, $deepSeaBlue);
  .icon-arrow {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
  .distribute_line {
    width: 45%;
    height: 150px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .button {
    margin: auto auto;
    .download-button {
      width: 80%;
      margin: 3vh 10vw;
      border-radius: 1vw;
      font-family: BoschSans-Bold, serif;
    }
    .seller-button {
      color: $marineBlue;
    }
    .user-button {
      color: $white;
      background-color: $boschBlue60;
    }
  }
  .login-description {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &-img {
      margin-top: 5vh;
      width: 23vw;
      margin-bottom: 1vh;
    }
    .title1 {
      font-size: 2.5rem;
    }
    .title2 {
      margin-top: 0.5rem;
      font-size: 1.8rem;
    }
    .title3 {
      margin-top: 4rem;
      font-size: 1.6rem;
    }
    &-title {
      font-family: BoschSans-Black, serif;
      color: $white;
    }
    &-tips {
      padding: 1vh 10vw;
      color: $white;
    }
  }
  .register-list {
    padding: 2vh 15vw;
    font-family: BoschSans-Bold, serif;
    font-size: 4vw;
    color: $white;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      padding: 1vh 0;
      img {
        width: 5vw;
        padding-right: 3vw;
      }
    }
  }
}
